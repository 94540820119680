import { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/Button';
import { useSocket } from '../../contexts/SocketContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { generateRandomString } from '../../helpers/common/generateRandomString';
import { useAlert } from '../../contexts/AlertContext';

export const CalibrationPage = () => {
  const { deviceToken1, deviceToken2 } = useParams();
  const { showAlert } = useAlert();

  const { sendEvent, subscribeToEvent } = useSocket();

  const [snapshots, setSnapshots] = useState([]);
  const [isUserReceivingSnapshot, setIsUserReceivingSnapshot] = useState(false);

  const handleSaveCalibration = async () => {
    if (snapshots.length !== 2) {
      showAlert('warning', 'You need to have two snapshots to save the calibration.');
      return;
    }

    const formData = new FormData();
    formData.append('deviceToken1', deviceToken1);
    formData.append('deviceToken2', deviceToken2);

    const filename1 = `snapshot1_${generateRandomString(5)}.jpg`;
    const filename2 = `snapshot2_${generateRandomString(5)}.jpg`;

    formData.append('file1', base64ToFile(snapshots[0], filename1));
    formData.append('file2', base64ToFile(snapshots[1], filename2));

    try {
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}device_calibration_histories`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      showAlert('success', 'Calibration saved successfully.');
    } catch (error) {
      console.error('Error while saving calibration:', error);
    }
  };

  const handleTakeSnapshot = () => {
    if (isUserReceivingSnapshot) return;
    sendEvent('take_stream_snapshot');
    setIsUserReceivingSnapshot(true);
  };

  useEffect(() => {
    subscribeToEvent('stream_snapshot', (data) => {
      setIsUserReceivingSnapshot(false);
      setSnapshots(data);
    });
  }, [subscribeToEvent]);

  const base64ToFile = (base64, filename) => {
    let byteString;
    let mimeString = '';

    if (base64.includes(',')) {
      byteString = atob(base64.split(',')[1]);
      mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    } else {
      byteString = atob(base64);
      mimeString = 'image/jpeg';
    }

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], filename, { type: mimeString });
  };

  return (
    <div className='w-full h-full p-2 bg-white'>
      <div className='flex items-center justify-between w-full'>
        <span className='text-lg font-semibold'>Calibrer les caméras</span>
        <div className='flex items-center gap-2'>
          <Button type='button' category='tertiary-btn' size='small' onClick={handleSaveCalibration}>
            Sauvegarder
          </Button>
          <Button type='button' category='secondary-btn' size='small' onClick={handleTakeSnapshot}>
            {snapshots.length ? 'Réessayer' : 'Prendre une capture'}
          </Button>
        </div>
      </div>

      <div className='flex flex-wrap justify-center gap-2 mt-2 camera-container'>
        {snapshots.length
          ? snapshots.map((snapshot, index) => (
              <div className='' key={index}>
                <img
                  src={`data:image/jpeg;base64,${snapshot}`}
                  alt={`Snapshot ${index + 1}`}
                  className='h-auto max-w-full rounded shadow'
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
