import { useState } from 'react';
import { InputSelect } from '../../components/forms/inputs/InputSelect';
import { useOrgData } from '../../contexts/OrgDataContext';
import { Button } from '../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';

export const OperatorPage = () => {
  const navigate = useNavigate();

  const { workstations } = useOrgData();

  const [workstationSelected, setWorkstationSelected] = useState(null);

  const handleClick = () => {
    if (workstationSelected) {
      navigate(`/operator/${workstationSelected.id}`);
    }
  };

  return (
    <div className='h-full overflow-hidden bg-gray-100'>
      <div className='w-full h-full flex flex-col justify-center items-center'>
        <h2 className='text-3xl font-semibold my-6'>Choisir un poste de travail</h2>
        <div className='w-80'>
          <InputSelect
            className='py-3'
            items={workstations}
            onChange={(workstation) => setWorkstationSelected(workstation)}
            itemToString={(workstation) => workstation?.name}
            placeholder='Choisir un poste'
            category='secondary'
            autoComplete='off'
          />
        </div>
        <div className='my-6'>
          <Button type='submit' category='primary-btn' size='small' onClick={handleClick}>
            Enregistrer
          </Button>
        </div>
      </div>
    </div>
  );
};
