/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { useOrgData } from '../../contexts/OrgDataContext';
import { usePatternSetup } from '../../contexts/PatternSetupContext';
import { Tooltip } from '../tooltip/Tooltip';
import {
  getIcon,
  getSubText,
  getText,
  getType,
  hasSubText,
  isHumanLog,
  LOGS_COMMON_SUBTYPES,
  LOGS_TYPES,
} from '../../services/log/log-service';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Badge from './Badge';
import clsx from 'clsx';
import { useEngine } from '../../contexts/EngineContext';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useTrialLiveContext } from '../../contexts/TrialLiveContext';

export const LogFactory = ({ view, log, index, onSelectLog, date, isChecked }) => {
  const renderedComponent = useMemo(() => {
    const logType = log.type;

    if (view === 'production') {
      if (logType === LOGS_TYPES.LOG1) {
        return <Log log={log} index={index} onSelectLog={onSelectLog} date={date} isChecked={isChecked} />;
      } else if (logType === LOGS_TYPES.PATTERN) {
        return <LogPattern log={log} index={index} onSelectLog={onSelectLog} date={date} isChecked={isChecked} />;
      } else if (logType === LOGS_TYPES.CALIBRATION) {
        return <LogCalibration log={log} index={index} date={date} />;
      }
    } else if (view === 'mini') {
      return <LogMini log={log} />;
    } else {
      return <LogDemo log={log} />;
    }
  }, [view, log, index, onSelectLog, date, isChecked]);

  return renderedComponent;
};

export default LogFactory;

const LogDemo = ({ log }) => {
  const { targets } = useOrgData();

  const renderedComponent = useMemo(() => {
    return (
      <div className='flex mt-4'>
        <div className='flex items-center justify-center w-full mb-2 sm:w-2/12 sm:mb-0'>
          <LogTime log={log} />
        </div>
        <LogLine />
        <div className='flex justify-center w-full mb-2 sm:w-2/12 sm:mb-0'>
          <LogIcon log={log} />
        </div>
        <div className='flex flex-col items-start justify-start w-full sm:w-6/12'>
          <div className='flex'>
            <p
              className={clsx(
                'text-xs sm:text-sm',
                getType(log) === 'PATTERN' || (getType(log) === 'ALERT' && 'font-bold'),
                getType(log) === 'ALERT' && 'text-error',
                'max-w-xs sm:max-w-sm',
              )}
            >
              {getText(log, {}, targets)}
            </p>
            {getType(log) === 'ALERT' && hasSubText(log) && (
              <span className='pl-2 font-semibold cursor-pointer text-warning sm:pl-3 whitespace-nowrap'>
                <Tooltip textHover='[more info]' text={getSubText(log)} position='right' />
              </span>
            )}
          </div>
          {getType(log) === 'PATTERN' && hasSubText(log) && (
            <span className='p-1 text-xs text-white bg-perception-blue'>{getSubText(log)}</span>
          )}
        </div>
      </div>
    );
  }, [log, targets]);

  return renderedComponent;
};

const Log = ({ log, onSelectLog }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { time, subtype } = log;
  const { eventTypes } = useOrgData();
  const { setFocusedLogDate } = useEngine();
  const { isPatternPanelOpened, logsChecked } = usePatternSetup();
  const { subtype: subtypeParams } = useParams();
  const navigate = useNavigate();

  const id = useMemo(() => `${subtype}_${time}`, [subtype, time]);
  const isChecked = useMemo(() => logsChecked[id], [id, logsChecked]);
  const pointColor = isHumanLog(log) ? 'bg-perception-gray-700' : 'bg-perception-gray-500';

  const isHighlightedTarget = log.subtype === Number(subtypeParams);
  const highlightClass = isHighlightedTarget ? 'animate-pulse-bg-once' : '';

  const handleMouseEnter = () => {
    setFocusedLogDate(time);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setFocusedLogDate(null);
    setIsHovered(false);
  };

  const redirectToEventSetup = (object_id) => {
    navigate(`/setup/${object_id}`);
  };

  return useMemo(
    () => (
      <div
        className={`flex log-element ${highlightClass}`}
        data-log-time={time}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isPatternPanelOpened && (
          <div className='flex items-center justify-center mr-2'>
            <input
              id={id}
              type='checkbox'
              className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600'
              onChange={(e) => onSelectLog(log, id, e.target.checked)}
              checked={isChecked || false}
            />
          </div>
        )}

        {/* Time */}
        <div className='flex items-center w-[70px]'>
          <LogTime log={log} />
        </div>

        {/* Line and point */}
        <div className='relative py-1 ml-20 transform translate-y-1/2 border-l border-perception-gray-800 last:translate-y-0'>
          <div className={`${pointColor} rounded-full w-[11px] h-[11px] absolute top-0 left-0 ml-[-6px] mt-[-5px]`} />
        </div>

        {/* Icon */}
        <div className='flex justify-center py-1 ml-36'>
          <LogIcon log={log} />
        </div>

        {/* Content */}
        <div className='flex items-center justify-between py-1 ml-4'>
          <p
            className={clsx(
              'text-xs sm:text-sm',
              { 'font-bold text-error': getType(log) === 'ALERT' },
              'max-w-xs sm:max-w-sm',
              { 'text-perception-gray-700': isHumanLog(log) },
            )}
          >
            {getText(log, {}, true, eventTypes)}
          </p>
          {getType(log) === 'ALERT' && hasSubText(log) && (
            <span className='pl-2 font-semibold cursor-pointer text-warning sm:pl-3 whitespace-nowrap'>
              <Tooltip textHover='[more info]' text={getSubText(log)} position='right' />
            </span>
          )}
        </div>
        {isHovered && (
          <div
            className='flex items-center gap-2 my-auto ml-auto mr-6 cursor-pointer'
            onClick={() => redirectToEventSetup(subtype)}
          >
            <span className='text-sm'>More detail</span>
            <ArrowTopRightOnSquareIcon className='w-5 h-5 cursor-pointer ' />
          </div>
        )}
      </div>
    ),
    [id, isChecked, isPatternPanelOpened, log, onSelectLog, eventTypes, isHovered],
  );
};

const LogPattern = ({ log }) => {
  const { isPatternPanelOpened } = usePatternSetup();
  const { eventTypes } = useOrgData();

  const [isPatternLogsVisible, setIsPatternLogsVisible] = useState(false);

  const patternLogs = useMemo(() => log?.pattern_logs, [log]);

  const togglePatternLogs = () => {
    setIsPatternLogsVisible(!isPatternLogsVisible);
  };

  return useMemo(
    () => (
      <LogLayout>
        {isPatternPanelOpened && (
          <div className='flex items-center justify-center invisible mr-2'>
            <input id={log.id} type='checkbox' className='w-4 h-4' />
          </div>
        )}

        {/* Time */}
        <div className='flex items-center w-[70px]'>
          <LogTime log={log} />
        </div>

        {/* Line and point */}
        <div className='relative py-1 ml-20 border-l border-perception-gray-800'>
          <div className='w-full h-[50px] absolute left-[-1px] top-[100%] border-l border-perception-gray-800' />
          <div className='bg-perception-blue rounded-full w-[11px] h-[11px] translate-y-10 absolute top-0 left-0 ml-[-6px] mt-[-5px]' />
        </div>

        <div
          className='ml-36 min-w-[400px] pr-10 flex border-[16px] transform translate-x-[-16px]
        my-1 bg-perception-black-500 border-perception-black-500 rounded-md'
        >
          {/* Icon */}
          <div className='flex justify-center'>
            <LogIcon log={log} />
          </div>

          {/* Log content */}
          <div className={`ml-4 flex flex-col items-start justify-center`}>
            <div className='flex items-center'>
              <ChevronDownIcon
                onClick={togglePatternLogs}
                className={clsx(
                  'w-5 h-5 text-perception-blue cursor-pointer',
                  isPatternLogsVisible && 'rotate-180 active',
                  !isPatternLogsVisible && 'rotate-180',
                )}
              />
              <p
                className='max-w-xs ml-2 text-xs font-bold text-white cursor-pointer sm:text-sm sm:max-w-sm'
                onClick={togglePatternLogs}
              >
                {log.pattern_name}
              </p>

              {/* Complete tag */}
              <div className='pl-2'>
                {log.percentage === 100 ? (
                  <Badge text='Complete' type='green' />
                ) : (
                  <Badge text={`Incomplete (${Math.round(log.percentage)}%)`} type='yellow' />
                )}
              </div>
            </div>
            {isPatternLogsVisible && (
              <div className='py-2'>
                {patternLogs
                  ? patternLogs.map((patternLog, i) => (
                      <div className='flex items-center justify-between mt-2 ml-10 text-sm' key={i}>
                        <div className='flex items-center'>
                          <LogIcon log={patternLog} size='small' />
                          <span className='ml-6'>{getText(patternLog, {}, true, eventTypes)}</span>
                        </div>
                        <span className='ml-10'>
                          <LogTime log={patternLog} />
                        </span>
                      </div>
                    ))
                  : ''}
              </div>
            )}
          </div>
        </div>
      </LogLayout>
    ),
    [isPatternLogsVisible, isPatternPanelOpened, log, patternLogs],
  );
};

const LogCalibration = ({ log, index, date }) => {
  const logText = `Calibration de la caméra ${log.device.name}`;
  return useMemo(
    () => (
      <div className={`flex log-element `}>
        {/* Time */}
        <div className='flex items-center w-[70px]'>
          <LogTime log={log} />
        </div>

        {/* Line and point */}
        <div className='relative py-1 ml-20 transform translate-y-1/2 border-l border-perception-gray-800 last:translate-y-0'>
          <div
            className={`bg-perception-gray-500 rounded-full w-[11px] h-[11px] absolute top-0 left-0 ml-[-6px] mt-[-5px]`}
          />
        </div>

        {/* Icon */}
        <div className='flex justify-center py-1 ml-36'>
          <LogIcon log={log} />
        </div>

        {/* Content */}
        <div className='flex items-center justify-between py-1 ml-4'>
          <p
            className={clsx(
              'text-xs sm:text-sm',
              { 'font-bold text-error': getType(log) === 'ALERT' },
              'max-w-xs sm:max-w-sm',
              'text-perception-gray-700',
            )}
          >
            {logText}
          </p>
        </div>
      </div>
    ),
    [log],
  );
};

const LogMini = ({ log }) => {
  const { object_id, subtype } = log;

  const { eventTypes } = useOrgData();
  const { targets } = useTrialLiveContext();

  let text;
  const isHuman = subtype === LOGS_COMMON_SUBTYPES.HUMAN_IN_AREA || subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA;
  const genericEventType = eventTypes.find(
    (eventType) => eventType.subtype === LOGS_COMMON_SUBTYPES.INTERACTION_WITH_NO_TRIAL,
  )?.name;

  if (isHuman) {
    text = eventTypes.find((eventType) => eventType.subtype === subtype)?.name;
  } else {
    const targetName = targets.find((target) => target.id === parseInt(object_id))?.name;
    text = genericEventType + ' ' + targetName;
  }

  return (
    <div className='flex mt-4'>
      <div className='flex items-center w-full mb-2 md:w-2/12 md:mb-0'>
        <LogTime log={log} />
      </div>
      <div className='flex flex-col items-center justify-center w-full mb-2 md:w-2/12 md:mb-0'>
        <LogIcon log={log} size='small' />
      </div>
      <div className='flex flex-col items-start justify-start w-full ml-2 md:w-8/12'>
        <div className='flex'>
          <p className={clsx('text-xs sm:text-sm', 'max-w-xs sm:max-w-sm', { 'text-perception-gray-700': isHuman })}>
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

// COMMON COMPONENTS

const LogTime = ({ log }) => <p className='text-xs'>{moment(log.time).format('HH:mm:ss')}</p>;

const LogIcon = ({ log, size }) => {
  const sizeClass = size === 'small' ? 'w-6 h-6' : 'w-10 h-10';
  const borderColor = log.type === LOGS_TYPES.PATTERN ? 'border-perception-blue' : 'border-perception-gray-500';

  return (
    <div className={`${sizeClass} rounded-full flex justify-center items-center border ${borderColor}`}>
      {getIcon(log, size)}
    </div>
  );
};

const LogLayout = ({ children }) => <div className='flex'>{children}</div>;

const LogLine = () => (
  <div className='relative flex justify-center w-full mb-2 sm:w-2/12 sm:mb-0'>
    <hr className='w-1/4 border-t border-perception-gray-500' />
    <hr className='hidden sm:block w-[150%] rotate-90 absolute border-t border-perception-gray-500' />
  </div>
);
