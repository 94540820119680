import { Panel } from '../../../../components/panels/Panel';
import { LogPanelFactory } from '../../../../components/logs/LogPanelFactory';
import { InputSelect } from '../../../../components/forms/inputs/InputSelect';
import { optionAggregationDuration, optionFilterView, useEngine } from '../../../../contexts/EngineContext';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { ServiceStatusIndicator } from '../../../../components/others/ServiceStatusIndicator';
import { Calendar } from '../../../../components/others/Calendar';

export const MainPanel = () => {
  const { workstations, workstationSelected, setWorkstationSelected } = useOrgData();

  const {
    logs,
    logsAggregationDuration,
    setLogsAggregationDuration,
    filterView,
    setFilterView,
    isLogsProcessing,
    setIsWorkstationOnChanged,
    setIsClientDateSetupExceeded,
    setIsInitialLogsRequested,
  } = useEngine();

  const isInputAggregationDisabled = () => filterView === 'DISPLAY_PATTERNS';

  const handleWorkstationChange = (selectedWorkstation) => {
    setIsClientDateSetupExceeded(false);
    setIsInitialLogsRequested(false);
    setIsWorkstationOnChanged(true);
    setWorkstationSelected(selectedWorkstation);
  };

  return (
    <div className='flex flex-col w-full h-full overflow-hidden'>
      <Panel category='main' title='ACTIVITY LOG'>
        <div className='w-full h-full flex flex-col'>
          <div className='flex items-center w-full'>
            <div className='w-1/4'>
              <InputSelect
                items={workstations}
                onChange={handleWorkstationChange}
                itemToString={(item) => item?.name}
                defaultValue={workstationSelected}
                disabled={isLogsProcessing}
              />
            </div>
            <div className='w-1/4 ml-3'>
              <InputSelect
                items={Object.values(optionFilterView)}
                onChange={(item) =>
                  setFilterView(Object.keys(optionFilterView).find((key) => optionFilterView[key].name === item.name))
                }
                itemToString={(item) => item?.name}
                defaultValue={optionFilterView[filterView]}
                disabled={isLogsProcessing}
              />
            </div>
            <div className='w-1/4 ml-3 mr-2'>
              <InputSelect
                items={Object.values(optionAggregationDuration)}
                onChange={(item) =>
                  setLogsAggregationDuration(
                    Object.keys(optionAggregationDuration).find(
                      (key) => optionAggregationDuration[key].value === item.value,
                    ),
                  )
                }
                itemToString={(item) => item?.name}
                defaultValue={optionAggregationDuration[logsAggregationDuration]}
                disabled={isInputAggregationDisabled() || isLogsProcessing}
              />
            </div>
            <Calendar useContext={useEngine} contextType='engine' />
            <span className='ml-auto'>
              <ServiceStatusIndicator view='prod' />
            </span>
          </div>
          <div className='flex-1 w-full pt-6 overflow-hidden relative'>
            <LogPanelFactory view='production' logs={logs} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
