import { PhotoIcon, PlusIcon, StopCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { patchData } from '../../../../../../services/api/api-tools';
import { Menu } from '../../../../../../components/menu/Menu';
import { HoverTextButton } from '../../../../../../components/buttons/HoverTextButton';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';

export const SituationPanel = ({
  situations,
  selectedSituation,
  setSelectedSituation,
  setIsSituationModalOpen,
  isViewMode,
  handleDeleteItem,
  specificationQueryKey,
  fixedErrors,
}) => {
  if (!situations?.length) return null;

  const renderedSituations = situations.map((situation) => (
    <SituationRow
      key={situation.id}
      situation={situation}
      selectedSituation={selectedSituation}
      setSelectedSituation={setSelectedSituation}
      handleDeleteItem={handleDeleteItem}
      specificationQueryKey={specificationQueryKey}
      fixedErrors={fixedErrors}
      isViewMode={isViewMode}
    />
  ));

  const handleCreation = () => {
    setIsSituationModalOpen(true);
  };

  return (
    <div className='h-full group/btn'>
      <div className='flex flex-col gap-2'>{renderedSituations}</div>
      {!isViewMode ? (
        <div className='mt-4'>
          <HoverTextButton onClick={handleCreation} disabled={false}>
            <PlusIcon className='w-4 h-4' />
            <span className='pl-2'>Create a new situation</span>
          </HoverTextButton>
        </div>
      ) : null}
    </div>
  );
};

const SituationRow = ({
  situation,
  selectedSituation,
  setSelectedSituation,
  handleDeleteItem,
  specificationQueryKey,
  fixedErrors,
  isViewMode,
}) => {
  const { errors } = situation;

  const queryClient = useQueryClient();

  const [menu, setMenu] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(situation.name);

  const isSituationActive = (situation) => {
    return situation?.id === selectedSituation?.id;
  };

  const situationHasError = errors.length > 0 && errors.some((error) => !fixedErrors.includes(error.id));
  const isMenuEnabled = menu && !isViewMode;

  const getStyle = () => {
    if (isSituationActive(situation)) {
      if (situationHasError) {
        return 'text-perception-warn-500 font-semibold';
      } else {
        return 'text-perception-blue font-semibold';
      }
    } else if (situationHasError) {
      return 'text-perception-warn-500';
    } else {
      return 'text-perception-gray-500';
    }
  };

  const getSituationIcon = (situation) => {
    const { video_situation } = situation;
    const className = `w-5 h-5`;

    if (video_situation) {
      return <StopCircleIcon className={clsx(className, getStyle())} />;
    } else {
      return <PhotoIcon className={clsx(className, getStyle())} />;
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alert,
    });
  };

  const handleClickSituation = (situation) => {
    setSelectedSituation(situation);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setMenu(null);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    setSelectedSituation(situation);
    setMenu(null);
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    handleDeleteItem('specification_situations', situation.id);
    setMenu(null);
  };

  const handleRename = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setMenu(null);
  };

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const handleNameSubmit = async () => {
    if (editedName.trim() === '') {
      setIsEditing(false);
      setEditedName(situation.name);
    } else if (editedName === situation.name) {
      setIsEditing(false);
    } else {
      const response = await patchData(`specification_situations/${situation.id}`, { name: editedName });

      if (!response) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsEditing(false);
      setEditedName(editedName);

      queryClient.setQueryData(specificationQueryKey, (oldData) => {
        if (oldData && oldData.length > 0) {
          const updatedData = {
            ...oldData[0],
            specification_situations: oldData[0].specification_situations.map((oldSituation) => {
              if (oldSituation.id === situation.id) {
                return { ...oldSituation, name: editedName };
              }
              return oldSituation;
            }),
          };
          return [updatedData];
        }
        return oldData;
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNameSubmit();
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setEditedName(situation.name);
    }
  };

  const handleBlur = () => {
    handleNameSubmit();
  };

  return (
    <div
      key={situation.id}
      className='flex gap-2 items-center cursor-pointer'
      onClick={() => handleClickSituation(situation)}
      onContextMenu={handleContextMenu}
    >
      {getSituationIcon(situation)}
      {isEditing ? (
        <input
          type='text'
          value={editedName}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className='text-xs text-perception-gray-500 ml-4 uppercase font-semibold bg-transparent border outline-none border-perception-blue'
          autoFocus
        />
      ) : (
        <span className={getStyle()}>{situation.name}</span>
      )}
      {isMenuEnabled && (
        <Menu menu={menu} setMenu={setMenu} onClose={handleCloseMenu}>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleEdit}>
            Edit
          </span>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleRename}>
            Rename
          </span>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleDelete}>
            Delete
          </span>
        </Menu>
      )}
    </div>
  );
};
