import moment from 'moment';
import { useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { Panel } from '../../../../../components/panels/Panel';
import { useOrgData } from '../../../../../contexts/OrgDataContext';
import { useEngine } from '../../../../../contexts/EngineContext';
import {
  _getDatasetDisplayData,
  getLineDatasetsFromPoints,
  mergeDatasetsByChartId,
  splitPointsBySubtype,
} from '../../../../../services/entity/chart/chart-service';
import { optionDateRange } from '../../../../../contexts/DashboardContext';
import { getInfluxdbData } from '../../../../../services/api/influxdb-tools';
import { GenericChart } from '../../../../dashboard/components/charts/GenericChart';
import { extractAndFilterSubtypes } from '../../../../../services/log/log-service';
import { SpinnerIcon } from '../../../../../components/loading/SpinnerIcon';

Chart.register(...registerables);

export const DashboardPanel = () => {
  const { workstationSelected, eventTypes } = useOrgData();
  const { logs, focusedLogDate, isWorkstationOnChanged } = useEngine();

  const [graphsData, setGraphsData] = useState({});
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const [maxY, setMaxY] = useState(0);

  const charts = [{ id: 'minidashboard', type: 'line' }];

  const getTemporalBar = (x, y) => {
    return {
      type: 'bar',
      order: 0,
      data: [
        {
          x: x,
          y: y,
        },
      ],
      backgroundColor: '#4164FD',
      maxBarThickness: 2,
    };
  };

  // When the logs in the main log panel change, we modify the start and end dates of our chart.
  useEffect(() => {
    if (logs.length) {
      const selectedDateRange = {
        ...optionDateRange[0],
        start: Date.parse(logs[logs.length - 1].time),
        stop: Date.parse(logs[0].time),
      };
      setSelectedDateRange(selectedDateRange);
    }
  }, [logs]);

  // When chart changes or when the dates change, we retrieve the data from InfluxDB to create the new dataset for our chart.
  useEffect(() => {
    const getChartData = async () => {
      // We retrieve the new data based on the new dates and format the dataset.
      const start = new Date(selectedDateRange.start).toISOString();
      const stop = new Date(selectedDateRange.stop).toISOString();

      const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected, true);
      if (subtypes.length === 0) return;

      const data = await getInfluxdbData(
        'engine',
        'events',
        [],
        [
          ['duration', '>', 0],
          ['subtype', '==', subtypes],
        ],
        start,
        stop,
        '15m',
        'sum',
        null,
        [], // ['subtype'] to split by subtype TODO
        null,
        null,
        true,
      );

      if (data.length === 0) return;

      // Simulate a fake chart/chartData pair to use generic graphs functions
      const activityChart = {
        id: 'activity_chart',
        type: 'line',
      };

      const activitiesData = [];
      for (const [subtype, points] of Object.entries(splitPointsBySubtype(data))) {
        const activityChartData = {
          id: 'activity_chart_data_' + subtype,
          series_name: 'Activity',
          series_color: null,
        };

        const activityData = _getDatasetDisplayData(activityChart, activityChartData, points, selectedDateRange);
        activitiesData.push(activityData);
      }

      // We format our datasets to be suitable for what the Chart.js library expects for building a graph.
      const allDatasets = activitiesData.map((dataset, datasetIndex) => {
        return getLineDatasetsFromPoints(dataset, datasetIndex, selectedDateRange);
      });

      // We merge all datasets sharing the same chart ID to have only one dataset for a graph.
      const mergeDatasets = mergeDatasetsByChartId(allDatasets);

      // In this new dataset, we search for the highest machine utilization value
      let maxUtilizationPercentage = 0;

      for (const dataset of mergeDatasets['activity_chart']) {
        dataset.data.forEach((point) => {
          if (parseInt(point.y) > parseInt(maxUtilizationPercentage)) {
            maxUtilizationPercentage = point.y;
          }
        });
      }

      setMaxY(maxUtilizationPercentage);

      // We retrieve the old temporal bar and adjust its x and y position based on the new dataset
      if (mergeDatasets?.datasets) {
        let bar = mergeDatasets.datasets.find((graphData) => graphData.type === 'bar');
        if (bar?.data) {
          const stop = selectedDateRange.stop - 5 * 60 * 1000;
          const formattedStop = moment(stop).format('YYYY-MM-DD HH:mm:ss');
          bar.data[0].x = moment(formattedStop);
          bar.data[0].y = maxUtilizationPercentage;
          mergeDatasets.datasets.push(bar);
        }
      }

      const formattedDataset = Object.entries(mergeDatasets).map(([chartId, datasets]) => ({
        chartId: chartId,
        datasets,
      }))[0];

      setGraphsData(formattedDataset);
      setIsChartLoading(false);
    };
    if (selectedDateRange) {
      if (selectedDateRange.start !== selectedDateRange.stop) {
        setIsChartLoading(true);
        getChartData();
      }
    }
  }, [selectedDateRange]);

  // When user focuses on a log in the panel, we modify the chart bar to represent on the dashboard where it is located temporarily.
  useEffect(() => {
    if (focusedLogDate) {
      if (graphsData?.datasets) {
        const newDatasets = [...graphsData.datasets];

        const existingBarIndex = newDatasets.findIndex((dataset) => dataset.type === 'bar');

        const x = moment(focusedLogDate).format('YYYY-MM-DD HH:mm:ss');
        const y = maxY;

        const newBar = getTemporalBar(x, y);

        if (existingBarIndex !== -1) {
          newDatasets[existingBarIndex] = newBar;
        } else {
          newDatasets.push(newBar);
        }

        setGraphsData({ ...graphsData, datasets: newDatasets });
      }
    }
  }, [focusedLogDate]);

  // The first time the graph loads, we ensure to initialize the time bar.
  useEffect(() => {
    if (graphsData?.datasets) {
      const newDatasets = [...graphsData.datasets];
      const existingBarIndex = newDatasets.findIndex((dataset) => dataset.type === 'bar');
      if (existingBarIndex === -1) {
        const stop = selectedDateRange.stop - 5 * 60 * 1000;

        const x = moment(stop).format('YYYY-MM-DD HH:mm:ss');
        const y = maxY;

        const newBar = getTemporalBar(x, y);

        newDatasets.push(newBar);
        setGraphsData({ datasets: newDatasets });
      }
    }
  }, [graphsData]);

  return (
    <Panel title='ACTIVITY'>
      {graphsData === null || Object.entries(graphsData).length === 0 ? (
        <p>No data found</p>
      ) : (
        <>
          <p className='px-2 py-1 text-sm lowercase text-perception-gray-600'>
            {workstationSelected.name} utilization rate
          </p>
          {isWorkstationOnChanged ? (
            <div className='absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full'>
              <SpinnerIcon />
            </div>
          ) : (
            <GenericChart
              charts={charts}
              type='main-graph'
              graphsData={graphsData}
              isLoading={isChartLoading}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={selectedDateRange}
              minify={true}
            />
          )}
        </>
      )}
    </Panel>
  );
};
