import moment from 'moment/moment';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useOrgData } from './OrgDataContext';
import { useSocket } from './SocketContext';
import {
  getAggregatedLogs,
  sortLogsByTime,
  filterArrayWithoutHumanLogs,
  filterArrayOnHumanLogs,
  extractAndFilterSubtypes,
} from '../services/log/log-service';
import { getLogs, getMoreLogs } from '../services/log/log-api';
import { useGetData } from '../services/api/api-tools';
import { detectPatternsInLogs } from '../services/entity/pattern/pattern-service';
import { useLocation } from 'react-router-dom';

export const optionAggregationDuration = {
  NONE: { id: 0, name: 'MINIMUM AGGREGATION', value: 600 },
  ONE_HOUR: { id: 1, name: '1 HOUR AGGREGATION', value: 3600 },
  ONE_DAY: { id: 2, name: '1 DAY AGGREGATION', value: 24 * 3600 },
};

export const optionFilterView = {
  DISPLAY_EVENTS_PATTERNS: { id: 0, name: 'DISPLAY EVENTS AND PATTERNS' },
  DISPLAY_PATTERNS: { id: 1, name: 'DISPLAY ONLY PATTERNS' },
  DISPLAY_ALL: { id: 2, name: 'DISPLAY ALL' },
};

const EngineContext = createContext();

export const EngineProvider = ({ children }) => {
  const { workstationSelected, eventTypes, calibrations } = useOrgData();
  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();

  const [dateRange, setDateRange] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    stop: Date.now(),
  });

  // State flags
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLogsProcessing, setIsLogProcessing] = useState(false);
  const [isInitialLogsRequested, setIsInitialLogsRequested] = useState(false);
  const [isWorkstationOnChanged, setIsWorkstationOnChanged] = useState(false);
  const [isClientDateSetupExceeded, setIsClientDateSetupExceeded] = useState(false);

  // Logs cycle state
  const [rawLogs, setRawLogs] = useState([]);
  const [aggregatedLogsForPatterns, setAggregatedLogsForPatterns] = useState([]);
  const [sortedAggregatedLogs, setSortedAggregatedLogs] = useState([]);
  const [detectedPatterns, setDetectedPatterns] = useState(null);
  const [logs, setLogs] = useState([]);

  // Filters state
  const [logsAggregationDuration, setLogsAggregationDuration] = useState(Object.keys(optionAggregationDuration)[0]);
  const [filterView, setFilterView] = useState(Object.keys(optionFilterView)[0]);

  // Keep the reference to the last log time retrieved
  const [lastLogTimeReference, setLastLogTimeReference] = useState(new Date().toISOString());

  const [focusedLogDate, setFocusedLogDate] = useState(null);

  const location = useLocation();
  const isSetupMode = location.pathname.includes('/demo/live');

  const patternsQueryKey = 'patternsDetection';
  const { data: patterns, refetch: refetchPatternsDetection } = useGetData(patternsQueryKey, 'patterns');

  const patternsWorkstation = useMemo(() => {
    if (patterns?.length) {
      return patterns.filter((pattern) => pattern.workstation.id === workstationSelected.id);
    } else {
      return [];
    }
  }, [patterns, workstationSelected]);

  /* Variables summary
   * rawLogs = all logs from the InfluxDB database
   * logsNeededForAggregation = filtered and sorted logs, with or without humans count events
   * sortedAggregatedLogs = aggregated logs, sorted by date
   * detectedPatterns = patterns detected, based on sortedAggregatedLogs
   * logs = contains all aggregated logs + detected patterns
   */

  // 1 - After updating filtered/sorted logs, apply aggregation on logs
  useEffect(() => {
    if (rawLogs.length) {
      console.info('[DEBUG]: new sorted logs', rawLogs.length);
      setIsLogProcessing(true);
      if (isSetupMode) setSortedAggregatedLogs(rawLogs);
      else {
        // For patterns, we keep all logs
        setAggregatedLogsForPatterns(getAggregatedLogs(rawLogs, optionAggregationDuration.NONE.value));

        // For regular logs we do not aggregate logs about humans count
        const logsNeededForAggregation = filterArrayWithoutHumanLogs(rawLogs);

        const humansCountLogs = filterArrayOnHumanLogs(rawLogs, filterView);

        setIsWorkstationOnChanged(false);

        setSortedAggregatedLogs([
          ...getAggregatedLogs(logsNeededForAggregation, optionAggregationDuration[logsAggregationDuration].value),
          ...humansCountLogs,
        ]);
      }
    } else {
      setLogs([]);
      setIsLogProcessing(false);
    }
  }, [rawLogs, filterView, logsAggregationDuration]);

  // 2 - Find patterns based on filtered and aggregated logs (with humans count events)
  useEffect(() => {
    if (sortedAggregatedLogs.length && patternsWorkstation) {
      if (!isWorkstationOnChanged) {
        console.info('[DEBUG]: Searching for patterns...');
        const detectedPatterns = detectPatternsInLogs(aggregatedLogsForPatterns, patternsWorkstation);
        setDetectedPatterns(detectedPatterns);
      }
    } else {
      if (isInitialLogsRequested) {
        setLogs([]);
        setIsLogProcessing(false);
      }
    }
  }, [sortedAggregatedLogs, patternsWorkstation, isWorkstationOnChanged]);

  // 3 - Combine logs, patterns, and calibrations for display
  useEffect(() => {
    if (sortedAggregatedLogs.length && detectedPatterns) {
      if (filterView === 'DISPLAY_PATTERNS') {
        setLogs(sortLogsByTime(detectedPatterns));
        setIsLogProcessing(false);
      } else {
        const calibrationsLogs = calibrations.map((calibration) => {
          const time = new Date(calibration.date * 1000).toISOString();
          return {
            ...calibration,
            time: time,
            duration: 0,
            type: 5,
          };
        });
        let combinedLogs = [...sortedAggregatedLogs, ...detectedPatterns, ...calibrationsLogs];
        setLogs(sortLogsByTime(combinedLogs));
        setIsLogProcessing(false);
      }
    }
  }, [detectedPatterns, calibrations]);

  // Retrieve logs at the first render or if the user has modified the date filters or the workstation.
  useEffect(() => {
    const getLogsForSelectedDateRange = async (start, stop) => {
      try {
        setIsLogProcessing(true);
        const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
        const response = await getLogs(subtypes, start, stop);
        if (response.length && filterArrayWithoutHumanLogs(response).length > 50) {
          setRawLogs(response);
          setLastLogTimeReference(response[response.length - 1]?.time);
        } else {
          const moreLogs = await getMoreLogs(stop, subtypes);
          if (!moreLogs.length || filterArrayWithoutHumanLogs(moreLogs).length < 50) {
            setIsClientDateSetupExceeded(true);
            setRawLogs(moreLogs);
            setLastLogTimeReference(moreLogs[moreLogs.length - 1]?.time);
          } else {
            setRawLogs(moreLogs);
            setLastLogTimeReference(moreLogs[moreLogs.length - 1]?.time);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsInitialLogsRequested(true);
      }
    };

    if (!isLogsProcessing && eventTypes?.length) {
      if (dateRange.start && dateRange.stop) {
        getLogsForSelectedDateRange(dateRange.start, dateRange.stop);
      }
    }
  }, [dateRange, eventTypes]);

  // subscribe to received new event from socket for this workstation
  useEffect(() => {
    if (!socket || workstationSelected.id === 0) return;

    const handleEvent = (data) => {
      console.info('[DEBUG] New event received:', data);
      data.time = moment.unix(data.time).toISOString();
      const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
      if (subtypes.some((subtype) => parseInt(subtype) === parseInt(data.subtype))) {
        setIsLogProcessing(true);
        setRawLogs((allLogs) => {
          return [...allLogs, data];
        });
      }
    };

    console.info('[DEBUG] Subscribe to event');
    subscribeToEvent('event', handleEvent);

    return () => unsubscribeFromEvent('event', handleEvent);
  }, [socket, workstationSelected]);

  useEffect(() => {
    setLogs([]);
  }, [workstationSelected]);

  // engine messages management
  useEffect(() => {
    if (!socket) return;

    const handleEvent = (data) => {
      console.info('[DEBUG] Receive processing info:', data);
      setIsProcessing(data.is_processing);
    };

    subscribeToEvent('processing', handleEvent);

    return () => unsubscribeFromEvent('processing', handleEvent);
  }, [socket]);

  const contextValue = useMemo(
    () => ({
      logs,
      setLogs,
      isProcessing,
      logsAggregationDuration,
      setLogsAggregationDuration,
      patterns,
      filterView,
      setFilterView,
      detectedPatterns,
      isLogsProcessing,
      setIsLogProcessing,
      setRawLogs,
      lastLogTimeReference,
      dateRange,
      setDateRange,
      focusedLogDate,
      setFocusedLogDate,
      isInitialLogsRequested,
      setIsInitialLogsRequested,
      isWorkstationOnChanged,
      setIsWorkstationOnChanged,
      isClientDateSetupExceeded,
      setIsClientDateSetupExceeded,
      setLastLogTimeReference,
      refetchPatternsDetection,
    }),
    [
      logs,
      setLogs,
      isProcessing,
      logsAggregationDuration,
      setLogsAggregationDuration,
      patterns,
      filterView,
      setFilterView,
      detectedPatterns,
      isLogsProcessing,
      setIsLogProcessing,
      setRawLogs,
      lastLogTimeReference,
      dateRange,
      setDateRange,
      focusedLogDate,
      setFocusedLogDate,
      isInitialLogsRequested,
      setIsInitialLogsRequested,
      isWorkstationOnChanged,
      setIsWorkstationOnChanged,
      isClientDateSetupExceeded,
      setIsClientDateSetupExceeded,
      setLastLogTimeReference,
      refetchPatternsDetection,
    ],
  );

  return <EngineContext.Provider value={contextValue}>{children}</EngineContext.Provider>;
};

export const useEngine = () => {
  return useContext(EngineContext);
};
