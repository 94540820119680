import { useGetData } from '../../../../services/api/api-tools';

export const PanelData = ({ workstation }) => {
  const { id, name } = workstation;

  const { data: patternFailures, isLoading } = useGetData('patternFailures', 'pattern_failures', {
    'patternEventType.target.workstation.id': id,
  });

  const getPatternFailureNotFixed = () => {
    return patternFailures.filter((patternFailure) => patternFailure.has_been_fixed === 0).length;
  };

  if (isLoading) return null;

  return (
    <div className='fixed bottom-0 left-0 w-full border-t border-perception-gray-300 bg-gray-100'>
      <div className='flex w-full justify-between px-4 py-2 text-sm'>
        <span className='uppercase font-bold text-md'>{name}</span>
        <span>{patternFailures.length} alertes détectées</span>
        <span>{getPatternFailureNotFixed()} non résolue</span>
        <span />
        <span />
      </div>
    </div>
  );
};
