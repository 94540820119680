import { getInfluxdbData } from '../api/influxdb-tools';
import { filterArrayWithoutHumanLogs } from './log-service';

// Fetch logs from a database for the specified stream IDs.
export const getLogs = async (subtypes, startTimestamp, stopTimestamp) => {
  const startDate = new Date(startTimestamp);
  const stopDate = new Date(stopTimestamp);

  const start = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
  const stop = new Date(stopDate.getTime() - stopDate.getTimezoneOffset() * 60000).toISOString();

  return await getInfluxdbData(
    'engine',
    'events',
    [],
    ['type', 'duration', ['subtype', '==', subtypes]],
    start,
    stop,
    '1ms',
    null,
    null,
    [],
    null,
    null,
    false,
    'desc',
  );
};

// Fetches additional logs from a database based on the last log time and stream IDs.
// lastLogTime format ex : "2024-04-01T12:00:00Z"
export const getMoreLogs = async (lastLogTime, subtypes, targetNonHumanLogsCount = 50, hoursToLookBack = 24) => {
  const lastLogTimeDate = new Date(lastLogTime);
  const installationClientDate = new Date(
    parseInt(process.env.REACT_APP_INSTALLATION_CLIENT_DATE) * 1000,
  ).toISOString();

  let allLogs = [];
  let iteration = 1;

  while (filterArrayWithoutHumanLogs(allLogs).length < targetNonHumanLogsCount) {
    const start = new Date(lastLogTimeDate.getTime() - hoursToLookBack * 60 * 60 * 1000 * iteration).toISOString();
    const stop = new Date(lastLogTimeDate.getTime() - hoursToLookBack * 60 * 60 * 1000 * (iteration - 1)).toISOString();

    if (start < installationClientDate) {
      break;
    }

    const response = await getInfluxdbData(
      'engine',
      'events',
      [],
      ['type', 'duration', ['subtype', '==', subtypes]],
      start,
      stop,
      '1ms',
      null,
      null,
      [],
      null,
      null,
      false,
      'desc',
    );

    allLogs.push(...response);

    iteration++;
  }

  return allLogs;
};
