import { Outlet } from 'react-router-dom';
import { EngineProvider } from '../../contexts/EngineContext';

export const EngineLayout = () => {
  return (
    <EngineProvider>
      <Outlet />
    </EngineProvider>
  );
};
