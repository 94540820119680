import { useMemo, useState } from 'react';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { ChatBubbleBottomCenterTextIcon, PlusIcon } from '@heroicons/react/24/outline';
import { patchData } from '../../../../../../services/api/api-tools';
import { Menu } from '../../../../../../components/menu/Menu';
import { HoverTextButton } from '../../../../../../components/buttons/HoverTextButton';
import { useQueryClient } from 'react-query';

export const CommentPanel = ({
  selectedComment,
  setSelectedComment,
  setIsCommentModalOpen,
  isViewMode,
  handleDeleteItem,
}) => {
  const { annotations, markerHovered, clickedPixel } = useAnnotation();

  if (!annotations?.length) return null;

  // Only retrieves annotations of the comment type, an annotation is considered a comment type if it does not have x and y coordinates.
  const commentAnnotations = annotations.filter((annotation) => !annotation.position?.x);

  const isModalOpen = !!clickedPixel || !!markerHovered;

  const renderedComments = commentAnnotations.map((comment) => (
    <CommentRow
      key={comment.id}
      comment={comment}
      selectedComment={selectedComment}
      setSelectedComment={setSelectedComment}
      handleDeleteItem={handleDeleteItem}
      isModalOpen={isModalOpen}
      isViewMode={isViewMode}
    />
  ));

  const handleCreation = () => {
    if (!isModalOpen) {
      setIsCommentModalOpen(true);
    }
  };

  return (
    <div className='h-full group/btn'>
      <div className='flex flex-col gap-2'>{renderedComments}</div>
      {!isViewMode ? (
        <div className='mt-4'>
          <HoverTextButton onClick={handleCreation} disabled={false}>
            <PlusIcon className='w-4 h-4' />
            <span className='pl-2'>Create a new comment</span>
          </HoverTextButton>
        </div>
      ) : null}
    </div>
  );
};

const CommentRow = ({ comment, selectedComment, setSelectedComment, handleDeleteItem, isModalOpen, isViewMode }) => {
  const { setIsMarkerSelected, annotationQueryKey } = useAnnotation();

  const queryClient = useQueryClient();

  const [menu, setMenu] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(comment.title);

  const isMenuEnabled = menu && !isViewMode;

  const commentStyle = useMemo(() => {
    if (comment) {
      const isActive = comment?.id === selectedComment?.id;
      if (isActive) {
        return 'text-perception-blue font-semibold uppercase';
      } else {
        return 'text-perception-gray-500 uppercase';
      }
    }
  }, [selectedComment, comment]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alert,
    });
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setMenu(null);
  };

  const handleClickComment = (comment) => {
    if (!isEditing && !isModalOpen) {
      setSelectedComment(comment);
      setIsMarkerSelected(true);
    }
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    setEditedTitle(comment.title);
    setSelectedComment(comment);
    setMenu(null);
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    handleDeleteItem('specification_annotations', comment.id);
    setSelectedComment(null);
    setMenu(null);
  };

  const handleRename = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setSelectedComment(null);
    setMenu(null);
  };

  const handleNameChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleNameSubmit = async () => {
    if (editedTitle.trim() === '') {
      setIsEditing(false);
      setEditedTitle(comment.title);
    } else if (editedTitle === comment.title) {
      setIsEditing(false);
    } else {
      const response = await patchData(`specification_annotations/${comment.id}`, { title: editedTitle });
      if (response) {
        queryClient.setQueryData(annotationQueryKey, (oldData) => {
          if (oldData && oldData.length > 0) {
            return oldData.map((annotation) => {
              if (annotation.id === comment.id) {
                return {
                  ...annotation,
                  title: editedTitle,
                };
              } else {
                return annotation;
              }
            });
          } else {
            return oldData;
          }
        });
      }

      setIsEditing(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNameSubmit();
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setEditedTitle(comment.title);
    }
  };

  const handleBlur = () => {
    handleNameSubmit();
  };

  return (
    <div
      key={comment.id}
      className='flex gap-2 items-center cursor-pointer'
      onClick={() => handleClickComment(comment)}
      onContextMenu={handleContextMenu}
    >
      <ChatBubbleBottomCenterTextIcon className={`${commentStyle} w-5 h-5`} />
      {isEditing ? (
        <input
          type='text'
          value={editedTitle}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className='text-xs text-perception-gray-500 ml-4 uppercase font-semibold bg-transparent border outline-none border-perception-blue'
          autoFocus
        />
      ) : (
        <span className={commentStyle}>{comment.title}</span>
      )}
      {isMenuEnabled && (
        <Menu menu={menu} setMenu={setMenu} onClose={handleCloseMenu}>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleEdit}>
            Edit
          </span>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleRename}>
            Rename
          </span>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleDelete}>
            Delete
          </span>
        </Menu>
      )}
    </div>
  );
};
