import moment from 'moment';
import { usePatternSetup } from '../../../../../contexts/PatternSetupContext';
import { Toggle } from '../../../../../components/forms/Toggle';
import { useEngine } from '../../../../../contexts/EngineContext';
import { useAlert } from '../../../../../contexts/AlertContext';

export const DataPattern = () => {
  const {
    isPatternPanelOpened,
    patternLastModified,
    patternIsActive,
    setPatternIsActive,
    patternName,
    patternEventTypes,
  } = usePatternSetup();

  const { detectedPatterns } = useEngine();
  const { showAlert } = useAlert();

  const getPatternDetectionCount = () => {
    if (detectedPatterns) {
      return detectedPatterns.filter((detectedPattern) => detectedPattern.pattern_name === patternName).length;
    } else {
      return 0;
    }
  };

  const handleToggle = () => {
    if (patternEventTypes.length >= 2) {
      setPatternIsActive(!patternIsActive);
    } else {
      showAlert('warning', 'A pattern must have at least two event types.');
    }
  };

  if (!isPatternPanelOpened) return null;

  return (
    <div className='mt-12 text-perception-gray-500'>
      <span className='text-md font-semibold mt-4'>DATA</span>
      <div className='flex flex-col justify-start mt-2'>
        <div className='flex justify-between w-full py-2 border-b-[1px] pb-2 border-perception-gray-500 text-sm mt-2'>
          <span>Detection count</span>
          <span>{getPatternDetectionCount()}</span>
        </div>
        <div className='flex justify-between  w-full py-2 border-b-[1px] pb-2 border-perception-gray-500 text-sm mt-2'>
          <span>{patternIsActive ? 'Activated' : 'Disabled'}</span>
          <Toggle isLeftLabel={true} enabled={patternIsActive} setEnabled={handleToggle} />
        </div>
        <div className='flex justify-between w-full py-2 border-b-[1px] pb-2 border-perception-gray-500 text-sm mt-2'>
          <span>Last modification</span>
          <span>{moment(patternLastModified).format('MMMM D, YYYY h:mm:ss A')}</span>
        </div>
      </div>
    </div>
  );
};
