import { useEffect, useMemo, useState } from 'react';
import { getPointSegmentationMask, getSegmentationMasks } from '../../../../../../services/ia/segmentation-api';
import {
  createImageCopy,
  createKonvaLayerFromImage,
  createMaskImageURL,
  drawMask,
  getImageCoordinates,
  getImageData,
  getImageDataUrl,
  getImageNativeCoordinates,
  getMasksNumber,
  getTargetMaskIndex,
} from '../../../../../../helpers/image/image-helper';
import { Stage } from 'react-konva';
import { Loader } from '../../../../../../components/loading/Loader';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { generateRandomString } from '../../../../../../helpers/common/generateRandomString';

/* This component allows the display of segmentation layers on top of an image or canvas. 
It supports mouse hover and segment selection. */
export const SegmentationLayer = ({
  frameSizes,
  displaySizes,
  isCommentModalOpen,
  isViewMode,
  isSegmentationLoad,
  setIsSegmentationLoad,
  originalFrameSelected,
}) => {
  const {
    canvasRef,
    clickedPixel,
    setClickedPixel,
    selectedFrame,
    activeMarker,
    isUserDragVideo,
    setGuidePopup,
    isInitialSegmentationDone,
    setIsInitialSegmentationDone,
  } = useAnnotation();

  const { width: frameWidth, height: frameHeight } = frameSizes;
  const { width: displayWidth, height: displayHeight } = displaySizes;

  const [masks, setMasks] = useState(null);
  const [currentMaskIndex, setCurrentMaskIndex] = useState(null);
  const [savedSegmentationFrame, setSavedSegmentationFrame] = useState([]);

  const frameFilename = selectedFrame?.filename;

  const isLayerDisabled = useMemo(() => {
    if (clickedPixel || activeMarker || isSegmentationLoad || isCommentModalOpen || isViewMode) {
      return true;
    } else {
      return false;
    }
  }, [clickedPixel, activeMarker, isSegmentationLoad, isCommentModalOpen, isViewMode]);

  const loadAndSetMasks = (url, frameWidth, frameHeight, setMasks) => {
    const masksImage = new Image();
    masksImage.onload = () => {
      setMasks(createKonvaLayerFromImage(masksImage, frameWidth, frameHeight));
    };
    masksImage.src = url;
  };

  const handleClick = async (e) => {
    if (canvasRef.current) {
      setGuidePopup(null);
      const clickCoordinates = getImageCoordinates(e);
      const nativeCoordinates = getImageNativeCoordinates(clickCoordinates, frameSizes, displaySizes);
      const uniqueId = generateRandomString(10);
      setClickedPixel({ id: uniqueId, position: nativeCoordinates });
    }
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = getImageNativeCoordinates(stage.getPointerPosition(), frameSizes, displaySizes);

    if (masks && pointerPosition) {
      const sizes = { width: frameWidth, height: frameHeight };
      const masksImageData = getImageData(masks, sizes);
      setCurrentMaskIndex(getTargetMaskIndex(masksImageData, pointerPosition));
    }
  };

  const handleMouseLeave = () => {
    setCurrentMaskIndex(null);
  };

  const applySegmentation = async () => {
    try {
      console.log('[DEBUG] Apply segmentation');
      setIsSegmentationLoad(true);
      const frameURL = await getImageDataUrl(originalFrameSelected);
      const masksURLs = await getSegmentationMasks(frameFilename, frameURL);
      loadAndSetMasks(masksURLs, frameWidth, frameHeight, setMasks);
      setSavedSegmentationFrame((prev) => [...prev, { id: frameFilename, masks: masksURLs }]);
      setIsSegmentationLoad(false);
    } catch (error) {
      console.error('An error occurred while segmenting everything: ', error);
    }
  };

  // When the masks change, this function redraws the canvas.
  const masksURLs = useMemo(() => {
    if (masks) {
      let imagesUrls = [];

      const isSpecificSegmentation = clickedPixel || activeMarker;
      const masksColor = isSpecificSegmentation ? [255, 100, 253] : [65, 100, 253];

      const masksImageData = getImageData(masks, frameSizes);
      const masksNumber = getMasksNumber(masksImageData);

      for (let maskIndex = 0; maskIndex <= masksNumber; maskIndex++) {
        const maskImageData = createImageCopy(masksImageData);
        if (isSpecificSegmentation) {
          // TODO : Draw border segmentation like Segment Anything from Meta
          drawMask(maskImageData, maskIndex, masksColor);
        } else {
          drawMask(maskImageData, maskIndex, masksColor);
        }

        imagesUrls.push(createMaskImageURL(maskImageData));
      }

      if (isSpecificSegmentation) {
        setCurrentMaskIndex(getMasksNumber(getImageData(masks, frameSizes)));
      }

      return imagesUrls;
    }
  }, [masks]);

  // Every time the image changes, either call the segmentation service or apply the saved mask if one already exists for that frame.
  useEffect(() => {
    if (!isUserDragVideo && !isViewMode && !activeMarker) {
      if (canvasRef.current && frameWidth > 0 && frameHeight > 0) {
        const savedMasks = savedSegmentationFrame.find((maskFrame) => maskFrame.id === frameFilename);

        if (savedMasks) {
          loadAndSetMasks(savedMasks.masks, frameWidth, frameHeight, setMasks);
        } else {
          applySegmentation();
        }
      }
    }
  }, [originalFrameSelected, frameSizes, isUserDragVideo, isViewMode]);

  // Allow the user to trigger the segmentation service by clicking on a pixel of the frame.
  useEffect(() => {
    const applySpecificSegmentation = async () => {
      try {
        let position;

        if (clickedPixel) {
          position = [clickedPixel.position.x, clickedPixel.position.y];
        } else if (activeMarker) {
          position = [activeMarker.position.x, activeMarker.position.y];
        }

        const maskURL = await getPointSegmentationMask(frameFilename, [position]);
        loadAndSetMasks(maskURL, frameWidth, frameHeight, setMasks);
        setIsInitialSegmentationDone(true);
      } catch (error) {
        console.error('An error occurred while segmenting points: ', error.message);
      }
    };

    if (clickedPixel || activeMarker) {
      applySpecificSegmentation();
    } else {
      const savedMasks = savedSegmentationFrame.find((maskFrame) => maskFrame.id === frameFilename);

      if (savedMasks) {
        loadAndSetMasks(savedMasks.masks, frameWidth, frameHeight, setMasks);
      } else if (isInitialSegmentationDone) {
        applySegmentation();
      }
    }
  }, [clickedPixel, activeMarker]);

  return (
    <div
      className={`absolute z-10 top-0 left-0 ${isLayerDisabled ? 'pointer-events-none' : ''}`}
      onClick={(e) => handleClick(e)}
    >
      {isSegmentationLoad ? <Loader category='transparent' /> : ''}
      {currentMaskIndex !== null && (
        <div className='absolute h-full w-full z-20 opacity-50 pointer-events-none blur-[2px]'>
          <img src={masksURLs[currentMaskIndex]} alt='current_mask' className='w-full h-full' />
        </div>
      )}
      <Stage
        width={displayWidth}
        height={displayHeight}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};
