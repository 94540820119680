import moment from 'moment/moment';
import { useOrgData } from '../../contexts/OrgDataContext';
import { usePatternSetup } from '../..//contexts/PatternSetupContext';
import {
  extractAndFilterSubtypes,
  filterArrayWithoutHumanLogs,
  getFilteredAndGroupedLogs,
  getGroupedLogs,
  LOGS_COMMON_SUBTYPES,
} from '../../services/log/log-service';
import { getMoreLogs } from '../../services/log/log-api';
import { LogFactory } from './LogFactory';
import { useEngine } from '../../contexts/EngineContext';
import { PauseCircleIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useRef, useState } from 'react';
import { Loader } from '../loading/Loader';
import { useSocket } from '../../contexts/SocketContext';
import { useTrialLiveContext } from '../../contexts/TrialLiveContext';

const MainLogPanelDemo = ({ logs }) => {
  const sortedLogs = [...logs].sort((a, b) => new Date(b.time) - new Date(a.time));
  const { groupedLogs, dates } = getFilteredAndGroupedLogs(sortedLogs, null);

  return (
    <div className='flex flex-col h-full text-perception-gray-500'>
      <div className='flex-1 overflow-y-auto'>
        <div className='min-w-full px-6 py-1 align-middle'>
          <div id='panel-logs-date' className='w-full'>
            {dates.length > 0 ? (
              dates.map((date) => (
                <div key={date} className='overflow-hidden'>
                  <span className='font-medium text-md text-perception-gray-500'>
                    {moment(date).format('DD/MM/YYYY')}
                  </span>
                  {groupedLogs[date].map((log, index) => (
                    <LogFactory key={`${log._value}_${index}`} log={log} />
                  ))}
                </div>
              ))
            ) : (
              <div className='text-sm text-perception-gray-500'>No logs found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MiniLogPanel = () => {
  const { workstationSelected } = useOrgData();
  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();
  const { targets } = useTrialLiveContext();

  const [logs, setLogs] = useState([]);
  const [isLogsProcessing, setIsLogProcessing] = useState(false);

  useEffect(() => {
    if (!socket) return;

    const handleEvent = (data) => {
      data.time = moment.unix(data.time).toISOString();
      const isUserLog = targets.some((target) => target.id === parseInt(data.object_id));
      const isHumanLog =
        data?.subtype === LOGS_COMMON_SUBTYPES.HUMAN_IN_AREA || data?.subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA;
      if (isUserLog || isHumanLog) {
        setIsLogProcessing(true);
        setLogs((allLogs) => {
          return [...allLogs, data];
        });
      }

      if (data?.subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA) {
        setIsLogProcessing(false);
      }
    };

    const handleProcessing = (data) => {
      console.info('[DEBUG] Receive processing info:', data);
      setIsLogProcessing(data.is_processing);
    };

    console.info('[DEBUG] Subscribe to events');
    subscribeToEvent('event', handleEvent);
    subscribeToEvent('processing', handleProcessing);

    return () => {
      unsubscribeFromEvent('event', handleEvent);
      unsubscribeFromEvent('processing', handleProcessing);
    };
  }, [socket, workstationSelected, targets]);

  if (!targets) return null;

  const { groupedLogs, dates } = getGroupedLogs(logs);

  return (
    <div className='flex flex-col h-full overflow-y-auto text-perception-gray-500'>
      <div className='flex items-center text-sm'>
        {isLogsProcessing ? (
          <>
            <svg
              className='w-5 h-5 mr-3 text-white animate-spin'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
            Processing
          </>
        ) : (
          <>
            <PauseCircleIcon className='w-5 h-5 mr-3' />
            Nothing currently detected
          </>
        )}
      </div>
      <div className='relative'>
        <div className='inset-0 flex items-center my-5' aria-hidden='true'>
          <div className='w-full border-t border-perception-gray-200' />
        </div>
      </div>
      <div id='panel-logs-date' className='h-full'>
        {dates.length ? (
          dates.map((date) => (
            <div key={date} className='overflow-hidden'>
              <span className='text-sm font-medium'>{moment(date).format('DD/MM/YYYY')}</span>
              {groupedLogs[date].map((log, index) => (
                <LogFactory key={index} view='mini' log={log} />
              ))}
            </div>
          ))
        ) : (
          <div className='flex flex-col items-center justify-center h-full text-perception-gray-200 text-md'>
            <ViewfinderCircleIcon className='w-8 h-8' />
            <span className='mt-2'>No logs generated yet</span>
          </div>
        )}
      </div>
    </div>
  );
};

const MainLogPanel = ({ logs }) => {
  const { workstationSelected, eventTypes } = useOrgData();
  const {
    isLogsProcessing,
    setRawLogs,
    setIsLogProcessing,
    lastLogTimeReference,
    setFocusedLogDate,
    dateRange,
    isClientDateSetupExceeded,
    setIsClientDateSetupExceeded,
    setLastLogTimeReference,
  } = useEngine();
  const { isPatternPanelOpened, setLogsChecked, patternEventTypes, setPatternEventTypes } = usePatternSetup();

  const scrollRef = useRef(null);
  const { groupedLogs, dates } = getGroupedLogs(logs);

  // relative to creation or editing pattern
  const handleSelectLog = (log, id, isChecked) => {
    const eventType = eventTypes.find((eventType) => eventType.subtype === log.subtype);
    const patternEventType = {
      id: id,
      order: patternEventTypes.length + 1,
      event_type: {
        id: eventType.id,
        name: eventType.name,
        subtype: eventType.subtype,
      },
      target: parseInt(log.object_id) > 0 ? { id: parseInt(log.object_id) } : null,
    };

    if (isChecked) {
      setPatternEventTypes((currentPatternEventTypes) => [...currentPatternEventTypes, patternEventType]);
    } else {
      setPatternEventTypes((currentPatternEventTypes) =>
        currentPatternEventTypes
          .filter((patternEventType) => patternEventType.id !== id)
          .map((patternEventType, index) => ({ ...patternEventType, order: index + 1 })),
      );
    }

    setLogsChecked((prevLogsChecked) => ({
      ...prevLogsChecked,
      [id]: isChecked,
    }));
  };

  const handleScroll = async () => {
    if (!scrollRef.current) return;

    const totalHeight = scrollRef.current.scrollHeight;
    const windowHeight = scrollRef.current.clientHeight;
    const scrollPosition = scrollRef.current.scrollTop;

    // Part that sets which log is hovered for the mini dashboard.
    const logsContainer = scrollRef.current;
    const visibleLogs = [];

    logsContainer.querySelectorAll('.log-element').forEach((logElement) => {
      const rect = logElement.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= logsContainer.clientHeight) {
        const logTime = logElement.getAttribute('data-log-time');
        visibleLogs.push(logTime);
      }
    });

    if (visibleLogs.length > 0) {
      setFocusedLogDate(visibleLogs[visibleLogs.length - 1]);
    }

    // Part responsible for detecting when to fetch new logs.
    if (lastLogTimeReference) {
      if (!isLogsProcessing) {
        if (scrollPosition + windowHeight >= totalHeight) {
          try {
            setIsLogProcessing(true);
            const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
            const newLogs = await getMoreLogs(lastLogTimeReference, subtypes);

            if (filterArrayWithoutHumanLogs(newLogs).length < 5) {
              setIsClientDateSetupExceeded(true);
              setRawLogs((prevRawLogs) => [...prevRawLogs, ...newLogs]);
            } else {
              setRawLogs((prevRawLogs) => [...prevRawLogs, ...newLogs]);
              setLastLogTimeReference(newLogs[newLogs.length - 1].time);
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
    } else {
      setIsLogProcessing(false);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollRef.current, lastLogTimeReference, isLogsProcessing]);

  useEffect(() => {
    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [workstationSelected, dateRange]);

  return (
    <div className='flex flex-col h-full text-perception-gray-500'>
      {isLogsProcessing && <Loader category='transparent' />}
      {isClientDateSetupExceeded && !logs.length ? (
        <div className='flex flex-col items-center justify-center h-full text-perception-gray-200 text-md'>
          <ViewfinderCircleIcon className='w-8 h-8' />
          <span className='mt-2'>No logs found</span>
        </div>
      ) : (
        ''
      )}
      <div ref={scrollRef} className='flex-1 overflow-y-auto'>
        <div className='min-w-full align-middle'>
          <div id='panel-logs-date' className='w-full pl-4'>
            {dates.length > 0
              ? dates.map((date) => (
                  <div key={date}>
                    <span className='font-medium text-md text-perception-gray-500'>
                      {moment(date).format('DD/MM/YYYY')}
                    </span>
                    {groupedLogs[date].map((log, index) => (
                      <LogFactory
                        key={`${log._value}_${index}`}
                        view='production'
                        log={log}
                        isPatternPanelOpened={isPatternPanelOpened}
                        onSelectLog={handleSelectLog}
                      />
                    ))}
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LogPanelFactory = memo(function LogPanelFactory({ view, logs }) {
  if (view === 'production') {
    return <MainLogPanel logs={logs} />;
  } else if (view === 'mini') {
    return <MiniLogPanel logs={logs} />;
  } else {
    return <MainLogPanelDemo logs={logs} />;
  }
});
