import { useEffect, useMemo, useRef, useState } from 'react';
import { useAnnotation } from '../../../../contexts/AnnotationContext';
import clsx from 'clsx';
import { applyBlur } from '../../../../helpers/image/image-helper';

export const TimelineVideo = ({ frames, selectedFrame, setSelectedFrame }) => {
  const { isUserDragVideo, setIsUserDragVideo, setIsInitialSegmentationDone, annotations } = useAnnotation();

  const frameRate = process.env.REACT_APP_ENGINE_FRAME_RATE;
  const totalFrames = frames.length;
  const duration = totalFrames / frameRate;

  const [currentTime, setCurrentTime] = useState(0);
  const timelineRef = useRef(null);

  const calculateFramesToDisplay = () => {
    let displayFactor = 1;
    while (100 / (totalFrames / displayFactor) < 10) {
      displayFactor += 1;
    }
    return displayFactor;
  };

  const calculateAnnotationTicks = (annotations, frameRate) => {
    if (annotations?.length) {
      let annotationTicks = [];
      annotations.forEach((annotation) => {
        const frameIndex = frames.findIndex((frame) => frame.id === annotation.specification_frame.id);
        const time = frameIndex / frameRate;
        const hasError = annotation.specification_error?.is_error_reviewed === false;
        annotationTicks.push({
          time,
          has_error: hasError,
        });
      });
      return annotationTicks;
    }
  };

  const annotationTicks = useMemo(() => calculateAnnotationTicks(annotations, frameRate), [annotations, frameRate]);

  const displayFactor = calculateFramesToDisplay();

  const mergeAnnotations = (frames, annotations) => {
    let mergedAnnotations = [];
    for (let frame of frames) {
      if (annotations) {
        const frameAnnotations = annotations.filter((annotation) => annotation.specification_frame.id === frame.id);
        if (frameAnnotations?.length) {
          mergedAnnotations = mergedAnnotations.concat(frameAnnotations);
        }
      }
    }
    return mergedAnnotations;
  };

  const framesPreview = useMemo(() => {
    const framesToDisplay = frames.filter((_, index) => index % displayFactor === 0);

    return framesToDisplay.map((frame, index) => {
      if (index === framesToDisplay.length - 1) {
        return {
          ...frame,
          specification_annotations: mergeAnnotations(frames.slice(index * displayFactor), annotations),
        };
      } else {
        return {
          ...frame,
          specification_annotations: mergeAnnotations(
            frames.slice(index * displayFactor, (index + 1) * displayFactor),
            annotations,
          ),
        };
      }
    });
  }, [frames, displayFactor, annotations]);

  const ticksToDisplay = Math.ceil(duration) / displayFactor + 1;

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  const handleTimelineClick = (e) => {
    const timeline = timelineRef.current;
    const rect = timeline.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = Math.min(Math.max(0, (offsetX / rect.width) * duration), duration);
    setCurrentTime(newTime);
  };

  const handleMouseDown = (e) => {
    setIsUserDragVideo(true);
    handleTimelineClick(e);
  };

  const handleMouseMove = (e) => {
    if (isUserDragVideo) {
      handleTimelineClick(e);
    }
  };

  const handleMouseUp = () => {
    setIsUserDragVideo(false);
  };

  // When the frame is changed from the timeline, we update it to reapply the segmentation.
  useEffect(() => {
    if (currentTime && frameRate && isUserDragVideo) {
      console.log('[DEBUG] Update frame from timeline');
      const selectedFrame = frames[Math.floor(currentTime * frameRate)];
      setIsInitialSegmentationDone(false);
      setSelectedFrame(selectedFrame);
    }
  }, [currentTime, frameRate, frames, setSelectedFrame]);

  useEffect(() => {
    if (selectedFrame) {
      const index = frames.findIndex((frame) => frame.id === selectedFrame.id);
      if (index !== -1 && !isUserDragVideo) {
        setCurrentTime(index / frameRate);
      }
    }
  }, [selectedFrame, frames, frameRate]);

  useEffect(() => {
    if (isUserDragVideo) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isUserDragVideo]);

  return (
    <div className='flex flex-col border-t relative border-perception-gray-800 bg-perception-black-800 h-[150px] w-full'>
      <div className='w-full h-full px-12'>
        <div
          ref={timelineRef}
          className='relative w-full h-full cursor-noner-pointer timeline-container'
          onMouseDown={handleMouseDown}
        >
          <div className='w-full h-2/6'>
            {/* Ticks line */}
            {Array.from({ length: ticksToDisplay }).map((_, tickIndex) => {
              const tickTime = tickIndex * displayFactor;
              const tickPosition = (tickTime / duration) * 100;
              const halfTickPosition = ((tickTime + displayFactor / 2) / duration) * 100;

              if (tickPosition > 100) {
                return null;
              }
              return (
                <div key={tickIndex} className='inline-block select-none' style={{ width: `${100 / ticksToDisplay}%` }}>
                  {/* TICK */}
                  <div
                    className='absolute border-l border-perception-gray-600'
                    style={{ height: '10px', left: `${tickPosition}%`, top: '5px' }}
                  ></div>
                  {/* HALF TICK */}
                  {halfTickPosition <= 100 && tickIndex < ticksToDisplay - 1 && (
                    <div
                      className='absolute border-l border-perception-gray-800'
                      style={{
                        height: '5px',
                        left: `${halfTickPosition}%`,
                        top: '5px',
                      }}
                    ></div>
                  )}
                  {/* TIME */}
                  <div
                    className='text-xs text-perception-gray-600'
                    style={{ position: 'absolute', left: `${tickPosition}%`, top: '20px' }}
                  >
                    {formatTime(tickTime)}
                  </div>
                </div>
              );
            })}
            {/* Annotation ticks */}
            {annotationTicks?.length &&
              annotationTicks.map((annotation, index) => {
                const { time, has_error } = annotation;
                const tickPosition = (time / duration) * 100;
                return (
                  <div
                    key={`annotation-${index}`}
                    className={`border-l ${has_error ? 'border-perception-warn-500' : 'border-perception-blue'} absolute`}
                    style={{ height: '20px', left: `${tickPosition}%`, top: '5px' }}
                  ></div>
                );
              })}
          </div>
          {/* Video frames previews */}
          <div className='flex w-full h-4/6' draggable={false}>
            {framesPreview.map((frame, index) => {
              return (
                <ImagePreview
                  key={frame.id}
                  frame={frame}
                  index={index}
                  currentTime={currentTime}
                  frameRate={frameRate}
                  framesPreview={framesPreview}
                  frames={frames}
                />
              );
            })}
          </div>
          {/* Current time indicator */}
          <div
            className='absolute top-0 z-20 h-full bg-perception-gray-500'
            style={{
              width: '2px',
              top: '0px',
              left: `${(currentTime / duration) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ImagePreview = ({ frame, currentTime, frameRate, framesPreview, frames }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const context = canvasRef.current.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `http://localhost:3001/proxy/${frame.filename}`;

    img.onload = () => {
      const originalWidth = img.width;
      const originalHeight = img.height;

      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;

      const widthRatio = canvasWidth / originalWidth;
      const heightRatio = canvasHeight / originalHeight;

      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      if (frame.bbox?.length) {
        frame.bbox.forEach(([x1, y1, x2, y2]) => {
          const scaledX1 = x1 * widthRatio;
          const scaledY1 = y1 * heightRatio;
          const scaledX2 = x2 * widthRatio;
          const scaledY2 = y2 * heightRatio;
          applyBlur(context, scaledX1, scaledY1, scaledX2 - scaledX1, scaledY2 - scaledY1, canvasRef);
        });
      }
    };

    img.onerror = (err) => {
      console.error('Error loading image', err);
    };
  }, [frame]);

  const width = 100 / framesPreview.length;
  const baseClass = 'select-none h-full';
  let variantClass;

  if (frame.specification_annotations?.length) {
    if (
      frame.specification_annotations.some((annotation) => annotation.specification_error?.is_error_reviewed === false)
    ) {
      variantClass = 'border-2 border-perception-warn-500';
    } else {
      variantClass = 'border-2 border-perception-blue';
    }
  }

  return (
    <canvas
      ref={canvasRef}
      width='100'
      height='75'
      className={clsx(baseClass, variantClass)}
      style={{
        opacity: frames.indexOf(frame) === Math.floor(currentTime * frameRate) ? 1 : 0.5,
        width: `${width}%`,
      }}
    />
  );
};
