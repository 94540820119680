import { useEffect, useMemo, useRef, useState } from 'react';
import { Panel } from '../../../../../components/panels/Panel';
import { FrameViewer } from './components/FrameViewer';
import { AnnotationProvider, useAnnotation } from '../../../../../contexts/AnnotationContext';
import { SegmentPanel } from './panels/SegmentPanel';
import { CommentModal } from './modals/CommentModal';
import { CommentPanel } from './panels/CommentPanel';
import { SituationPanel } from './panels/SituationPanel';
import { StatusModal } from './modals/StatusModal';
import { patchData, postData } from '../../../../../services/api/api-tools';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useAlert } from '../../../../../contexts/AlertContext';
import { ActionPanel } from './panels/ActionPanel';
import { ConfirmationModal } from './modals/ConfirmationModal';
import { NewSituationModal } from './modals/NewSituationModal';

export const Annotation = ({ specification, refetchSpecification, specificationQueryKey }) => {
  const { specification_situations: situations, state } = specification;

  const { showAlert } = useAlert();

  const [selectedSituation, setSelectedSituation] = useState(null);

  const [isSituationModalOpen, setIsSituationModalOpen] = useState(false);
  const [isEditingError, setIsEditingError] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  const [fixedErrors, setFixedErrors] = useState([]);

  // Contains all errors related to this specifications sheet.
  const specificationErrors = useMemo(() => {
    const errors = [];

    situations.forEach((situation) => {
      situation.specification_frames.forEach((frame) => {
        const frameErrors = frame.specification_annotations.reduce((acc, annotation) => {
          if (annotation.specification_error && !fixedErrors.includes(annotation.specification_error.id)) {
            acc.push(annotation.specification_error);
          }
          return acc;
        }, []);

        errors.push(...frameErrors);
      });
    });

    return errors;
  }, [specification, fixedErrors]);

  // Determine whether or not a modal should be displayed to help the user understand the status of their specifications sheet.
  const showSpecificationStatusModal = useMemo(() => {
    if (state >= 3) {
      if (!isEditingError && !isViewMode) {
        return true;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [state, isEditingError, isViewMode]);

  const closeSituationModal = () => {
    setIsSituationModalOpen(false);
  };

  const closeCommentModal = () => {
    setSelectedComment(null);
    setIsCommentModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteItem = (path, id, refetch) => {
    setItemToDelete({ path: path, id: id, refetch: refetch });
    setIsConfirmationModalOpen(true);
  };

  const handleSpecificationSubmission = async () => {
    const confirmSpecification = async () => {
      let response = await patchData(`specifications/${specification.id}`, { state: 3 });
      if (response?.state) {
        await postData('notify-specification-change', {});
        refetchSpecification().then(() => {
          setIsEditingError(false);
        });
      }
    };

    if (specificationErrors?.length) {
      const isErrorsReviewed = specificationErrors.every((error) => error.is_error_reviewed);
      if (isErrorsReviewed) {
        confirmSpecification();
      } else {
        showAlert('warning', 'Oops! You need to reviewed all errors before confirm!');
      }
    } else {
      confirmSpecification();
    }
  };

  const handleFixError = (error) => {
    setFixedErrors((prevFixedErrors) => [...prevFixedErrors, error]);
  };

  useEffect(() => {
    if (selectedComment) {
      setIsCommentModalOpen(true);
    } else {
      setIsCommentModalOpen(false);
    }
  }, [selectedComment]);

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current && situations.length) {
      setSelectedSituation(situations[0]);
      hasInitialized.current = true;
    }
  }, [situations, setSelectedSituation]);

  return (
    <AnnotationProvider>
      <OverlayGuide />
      {/* LEFT PANEL */}
      <div className='flex w-1/6 h-full'>
        <div className='flex flex-col w-full h-full'>
          <div className='flex flex-col h-1/2'>
            <Panel title='POINTS OF INTEREST'>
              <div className='flex flex-col gap-1'>
                <SegmentPanel
                  selectedSituation={selectedSituation}
                  isViewMode={isViewMode}
                  handleDeleteItem={handleDeleteItem}
                />
              </div>
            </Panel>
          </div>
          <div className='flex flex-col h-1/2'>
            <Panel title='COMMENTS'>
              <div className='flex flex-col gap-1'>
                <CommentPanel
                  selectedComment={selectedComment}
                  setSelectedComment={setSelectedComment}
                  setIsCommentModalOpen={setIsCommentModalOpen}
                  isViewMode={isViewMode}
                  handleDeleteItem={handleDeleteItem}
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>
      {/* MID PANEL */}
      <div className='flex-1'>
        <div className='flex flex-col w-full h-full overflow-hidden'>
          <Panel category='main' title='VIEW'>
            <ViewModeIcon isViewMode={isViewMode} />
            {selectedSituation && (
              <FrameViewer
                selectedSituation={selectedSituation}
                specification={specification}
                isCommentModalOpen={isCommentModalOpen}
                isSituationModalOpen={isSituationModalOpen}
                closeSituationModal={closeSituationModal}
                isViewMode={isViewMode}
                handleFixError={handleFixError}
              />
            )}
          </Panel>
        </div>
      </div>
      {/* RIGHT PANEL */}
      <div className='flex w-1/6 h-full'>
        <div className='flex flex-col w-full h-full'>
          <div className='flex flex-col h-1/2'>
            <Panel title='SITUATIONS'>
              <SituationPanel
                situations={situations}
                selectedSituation={selectedSituation}
                setSelectedSituation={setSelectedSituation}
                setIsSituationModalOpen={setIsSituationModalOpen}
                isViewMode={isViewMode}
                handleDeleteItem={handleDeleteItem}
                specificationQueryKey={specificationQueryKey}
                fixedErrors={fixedErrors}
              />
            </Panel>
          </div>
          <div className='flex flex-col h-1/2'>
            <Panel title='ACTIONS'>
              <ActionPanel
                isViewMode={isViewMode}
                handleSpecificationSubmission={handleSpecificationSubmission}
                setIsCommentModalOpen={setIsCommentModalOpen}
                setIsSituationModalOpen={setIsSituationModalOpen}
                specification={specification}
                specificationErrors={specificationErrors}
              />
            </Panel>
          </div>
        </div>
      </div>
      {/* SPECIFICATION MODALS */}
      {isCommentModalOpen ? (
        <CommentModal selectedComment={selectedComment} closeCommentModal={closeCommentModal} isViewMode={isViewMode} />
      ) : null}
      {showSpecificationStatusModal ? (
        <StatusModal
          specification={specification}
          setIsEditingError={setIsEditingError}
          setIsViewMode={setIsViewMode}
        />
      ) : null}
      {isConfirmationModalOpen ? (
        <ConfirmationModal
          specification={specification}
          setSelectedSituation={setSelectedSituation}
          closeConfirmationModal={closeConfirmationModal}
          itemToDelete={itemToDelete}
          specificationQueryKey={specificationQueryKey}
        />
      ) : null}
      {isSituationModalOpen || situations.length === 0 ? (
        <NewSituationModal
          specification={specification}
          closeSituationModal={closeSituationModal}
          setSelectedSituation={setSelectedSituation}
          specificationQueryKey={specificationQueryKey}
          situations={situations}
        />
      ) : null}
    </AnnotationProvider>
  );
};

const OverlayGuide = () => {
  const { guidePopup } = useAnnotation();

  if (guidePopup) {
    return <div className='fixed inset-0 z-40 bg-black opacity-40' />;
  }
};

const ViewModeIcon = ({ isViewMode }) => {
  if (isViewMode) {
    return (
      <div className='absolute flex items-center gap-2 pt-4 pointer-events-none right-5 top-5'>
        <LockClosedIcon className='w-5 h-5 text-perception-error-500' />
        <p className='font-semibold text-perception-gray-300'>VIEW MODE</p>
      </div>
    );
  }
};
