import { useEffect, useState } from 'react';
import { Banners } from './components/Banners';
import { Error } from '../error/Error';
import { Notification } from '../../common/Notification';
import { getText } from '../../../../../services/log/log-service';
import { createPatternFailure } from '../../../../../services/entity/pattern-failure/pattern-failure-api';
import { useOrgData } from '../../../../../contexts/OrgDataContext';
import { useSocket } from '../../../../../contexts/SocketContext';
import { useParams } from 'react-router-dom';

const getCurrentTimestampInSeconds = () => Math.floor(Date.now() / 1000);

export const Processing = ({
  patternsWithScore,
  logs,
  handleReset,
  isErrorOnArea,
  completedPattern,
  setCompletedPattern,
  errorHistory,
  setErrorHistory,
}) => {
  const { workstationId } = useParams();

  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();
  const { eventTypes } = useOrgData();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [remainingSteps, setRemainingSteps] = useState([]);

  // Manage the time stamping of the latest update to enable the end of processing in case of prolonged inactivity.
  const [lastUpdated, setLastUpdated] = useState(getCurrentTimestampInSeconds());

  // Relative to log & pattern detection
  const lastLog = logs[logs.length - 1];

  const nonZeroScorePatterns = patternsWithScore.filter((pattern) => pattern.score > 0);
  const sortedPatternsWithScore = nonZeroScorePatterns.sort((a, b) => b.score - a.score).slice(0, 4);
  const [highestScorePattern, ...otherPatterns] = sortedPatternsWithScore;

  // Return, based on the current pattern and its validated logs, the remaining number of steps to complete the process.
  const getRemainingSteps = () => {
    return highestScorePattern.pattern_event_types.filter((patternEventType) => !patternEventType.validated);
  };

  // If the current pattern is marked as completed and the last received log is considered 'finished',
  // then the process is completed, and we display a green banner to the user
  useEffect(() => {
    if (highestScorePattern?.is_complete && lastLog?.is_finished) {
      setCompletedPattern(highestScorePattern);
    }
  }, [logs]);

  // If a change is detected in the received patterns with scores, we need to process to update the display
  useEffect(() => {
    if (patternsWithScore.length) {
      if (highestScorePattern?.has_error) {
        setIsErrorModalOpen(true);
      } else if (highestScorePattern) {
        setIsErrorModalOpen(false);
        setRemainingSteps(getRemainingSteps);
      }
    }
  }, [patternsWithScore]);

  // If there is no activity detected on the workstation for 10 minutes, the process is deemed abandoned
  useEffect(() => {
    const resetProcess = async () => {
      const errorSavePromises = errorHistory.map(async (error) => {
        return createPatternFailure(error.id, error.has_been_fixed);
      });

      await Promise.all(errorSavePromises);

      handleReset();
      setErrorHistory([]);
    };

    const timeoutId = setTimeout(() => {
      const currentTimestamp = getCurrentTimestampInSeconds();
      if (currentTimestamp - lastUpdated >= 600) {
        resetProcess();
      }
    }, 1800000);

    if (logs) {
      setLastUpdated(getCurrentTimestampInSeconds());
    }

    return () => clearTimeout(timeoutId);
  }, [logs, lastUpdated, errorHistory]);

  // If we detect a successful pattern, then we wait for 2 seconds and reset the states to start another cycle
  useEffect(() => {
    if (completedPattern) {
      errorHistory.map((error) => {
        createPatternFailure(error.id, error.has_been_fixed);
      });
      const timeoutId = setTimeout(() => {
        setCompletedPattern(null);
        handleReset();
        setErrorHistory([]);
      }, 120000);

      const handleForceReset = (data) => {
        if (parseInt(data.workstation_id) === parseInt(workstationId)) {
          setCompletedPattern(null);
          handleReset();
          setErrorHistory([]);
        }
      };

      if (socket) {
        subscribeToEvent('success-ihm-reset', handleForceReset);
      }

      return () => {
        clearTimeout(timeoutId);
        unsubscribeFromEvent('success-ihm-reset', handleReset);
      };
    }
  }, [completedPattern, setCompletedPattern]);

  if (!eventTypes) return null;

  return (
    <div className='flex flex-col w-full px-32 py-8'>
      {isErrorModalOpen && !isErrorOnArea.state ? (
        <Error
          logs={logs}
          highestScorePattern={highestScorePattern}
          errorHistory={errorHistory}
          setErrorHistory={setErrorHistory}
        />
      ) : (
        ''
      )}
      {/* Pattern area */}
      <Banners
        highestScorePattern={highestScorePattern}
        otherPatterns={otherPatterns}
        success={{ state: completedPattern, error_history: errorHistory }}
      />
      {/* Notification area */}
      <div className='flex flex-col py-6'>
        {completedPattern ? (
          <Success workstationId={workstationId} />
        ) : (
          <InProgress
            remainingSteps={remainingSteps}
            highestScorePattern={highestScorePattern}
            lastLog={lastLog}
            logs={logs}
          />
        )}
      </div>
    </div>
  );
};

const InProgress = ({ remainingSteps, highestScorePattern, lastLog, logs }) => {
  const { eventTypes } = useOrgData();

  // Based on the latest logs and the current pattern, determine the type of notification to display in the 'in progress' area
  const renderStepInProcess = () => {
    if (lastLog?.is_finished) {
      // make sure that all logs are marked as finished.
      const unfinishedLog = logs.find((log) => !log.is_finished);
      if (unfinishedLog) {
        return <Notification stepCategory='in progress' text={getText(unfinishedLog, {}, true, eventTypes)} />;
      } else if (!highestScorePattern?.complete) {
        return <Notification stepCategory='on waiting' text='En attente de la prochaine étape' />;
      }
    } else {
      return <Notification stepCategory='in progress' text={getText(lastLog, {}, true, eventTypes)} />;
    }
  };

  return (
    <>
      <h2 className='py-6 text-2xl font-bold tracking-tight'>EN COURS</h2>
      <div className='my-2 h-[125px]'>{renderStepInProcess()}</div>
      <h2 className='py-6 text-2xl font-bold tracking-tight'>A VENIR </h2>
      {remainingSteps.map((step) => (
        <div key={step.id} className='my-2 h-[100px] opacity-90'>
          <Notification
            step={step}
            highestScorePattern={highestScorePattern}
            stepCategory='on list'
            text={step.event_type?.name}
            remainingSteps={remainingSteps}
          />
        </div>
      ))}
    </>
  );
};

const Success = ({ workstationId }) => {
  if (parseInt(workstationId) !== 9) return null;
  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-6 text-2xl'>
        <h3 className='text-3xl font-semibold'>Point à vérifier</h3>
        <span className='mt-2'>
          1. Assurez-vous de suivre le processus 5S pour ranger et organiser votre espace de travail.
        </span>
        <span>2. Évacuez les copeaux régulièrement pour maintenir un environnement de travail propre et sûr.</span>
        <span>3. Pensez à conserver vos EPI jusqu&apos;à la prochaine opération.</span>
        <span>
          3. Tenez compte du nombre de cycles restants avant la prochaine maintenance : 13 sur 15 cycles réalisés.
        </span>
      </div>
    </div>
  );
};
