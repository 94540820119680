import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../../../components/buttons/Button';
import { Modal } from '../../../Modal';
import { deleteData } from '../../../../../../services/api/api-tools';
import { useQueryClient } from 'react-query';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';

export const ConfirmationModal = ({
  specification,
  closeConfirmationModal,
  itemToDelete,
  specificationQueryKey,
  setSelectedSituation,
}) => {
  const { specification_situations: situations } = specification;
  const { path, id } = itemToDelete;

  const queryClient = useQueryClient();

  const { annotationQueryKey } = useAnnotation();

  const deleteItem = async () => {
    try {
      await deleteData(path, id);

      if (path === 'specification_annotations') {
        queryClient.setQueryData(annotationQueryKey, (oldData) => {
          if (oldData && oldData.length > 0) {
            return oldData.filter((annotation) => annotation.id !== id);
          } else {
            return oldData;
          }
        });
      } else if (path === 'specification_situations') {
        queryClient.setQueryData(specificationQueryKey, (oldData) => {
          if (oldData && oldData.length > 0) {
            const updatedData = { ...oldData[0] };

            updatedData.specification_situations = updatedData.specification_situations.filter(
              (oldSituation) => oldSituation.id !== id,
            );

            return [updatedData];
          }
          return oldData;
        });

        if (situations.length) {
          setSelectedSituation(situations[0]);
        } else {
          setSelectedSituation(null);
        }
      }

      closeConfirmationModal();
    } catch (err) {
      console.error(`Oops! Something went wrong... ${err}`);
    }
  };

  return (
    <Modal type='error' closeModal={closeConfirmationModal} className='gap-2 w-[600px]'>
      <h2 className='text-2xl font-semibold'>Confirm deletion</h2>
      <p className='text-md pt-4'>
        Are you sure you want to proceed with the deletion, this is a definitive action and it will not be possible to
        go back ?
      </p>
      <div className='flex gap-2 pt-6'>
        <Button size='small' category='deletion-btn' onClick={deleteItem}>
          Confirm
        </Button>
        <Button size='small' category='secondary-btn' onClick={closeConfirmationModal}>
          Cancel
        </Button>
      </div>
      <ExclamationCircleIcon className='w-10 h-10 text-perception-error-500 absolute right-6 top-6' />
    </Modal>
  );
};
