import { useEffect, useState } from 'react';
import { Notification } from '../../common/Notification';
import { isLogMatchEventPattern } from '../../../../../services/log/log-service';
import { getText } from '../../../../../services/log/log-service';
import { fetchData } from '../../../../../services/api/api-tools';
import { useOrgData } from '../../../../../contexts/OrgDataContext';

const ERROR_TYPES = [
  { id: 0, name: 'CORRECT LOGS NOT IN ORDER' },
  { id: 1, name: 'UNEXPECTED LOGS FOUND' },
];

export const Error = ({ logs, highestScorePattern, errorHistory, setErrorHistory, isErrorOnArea }) => {
  const { eventTypes } = useOrgData();

  const [errorToFixed, setErrorToFixed] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorVideoUrl, setErrorVideoUrl] = useState('');

  const lastLog = logs[logs.length - 1];
  const patternEventTypes = highestScorePattern?.pattern_event_types;

  // Checks the logs to identify where the error occurred and what type of error it is
  const verifyWorkflow = () => {
    for (let i = 0; i < logs.length; i++) {
      if (!patternEventTypes[i].validated) {
        // here we check whether the error is due to an unexpected action or simply an ordering issue
        let errorType = isLogExpectedByPattern(logs[i], patternEventTypes) ? 0 : 1;
        if (errorType) {
          errorType = ERROR_TYPES.find((errorType) => (errorType.name = 'UNEXPECTED LOGS FOUND')).id;
        } else {
          errorType = ERROR_TYPES.find((errorType) => (errorType.name = 'CORRECT LOGS NOT IN ORDER')).id;
        }

        setErrorToFixed({ ...patternEventTypes[i], error_type: errorType });
        setErrorHistory([
          ...errorHistory,
          {
            ...patternEventTypes[i],
            has_been_fixed: 0,
            type: errorType,
            id: patternEventTypes[i].id,
          },
        ]);

        break;
      }
    }
  };
  // Returns true if the log is expected by the pattern, regardless of the order; otherwise, returns false
  const isLogExpectedByPattern = (log, patternEventTypes) => {
    return (
      patternEventTypes.find((patternEventType) => {
        return isLogMatchEventPattern(patternEventType, log);
      }) !== undefined
    );
  };

  // If we have an error to fixed, we set the right message to show and the right content
  useEffect(() => {
    const getPatternEventTypeVideo = async (patternEventTypeId) => {
      try {
        const patternEventTypeVideo = await fetchData(`pattern_event_types/${patternEventTypeId}/file`);
        setErrorVideoUrl(patternEventTypeVideo);
      } catch (err) {
        console.error('error:', err);
      }
    };

    if (errorToFixed) {
      getPatternEventTypeVideo(errorToFixed.id);
      if (errorToFixed.error_type === ERROR_TYPES.find((errorType) => errorType.name === 'UNEXPECTED LOGS FOUND').id) {
        setErrorMessage({
          expected_message: `Action attendue : ${errorToFixed?.event_type?.name}`,
          received_message: `Action non attendue : ${getText(lastLog, {}, true, eventTypes)}`,
        });
      } else if (
        errorToFixed.error_type === ERROR_TYPES.find((errorType) => errorType.name === 'CORRECT LOGS NOT IN ORDER').id
      ) {
        setErrorMessage({
          expected_message: `Action attendue : ${errorToFixed?.event_type?.name}`,
          received_message: `Action exécutée : ${getText(lastLog, {}, true, eventTypes)}`,
        });
      }
    }
  }, [errorToFixed]);

  useEffect(() => {
    if (highestScorePattern?.has_error) {
      verifyWorkflow();
    }
  }, [highestScorePattern]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full ${errorToFixed ? 'bg-perception-error-500' : 'bg-perception-warn-500'} flex flex-col justify-center items-center z-50`}
    >
      <ErrorInPattern errorVideoUrl={errorVideoUrl} errorMessage={errorMessage} />
      <ErrorInArea isErrorOnArea={isErrorOnArea} />
    </div>
  );
};

const ErrorInPattern = ({ errorVideoUrl, errorMessage }) => {
  if (!errorMessage) return null;

  return (
    <>
      <div className='flex w-3/6 gap-4'>
        <div className={`${errorVideoUrl?.fileUrl ? 'w-1/2' : 'w-full'} border-4 border-dashed`}>
          <h2 className='py-24 text-center text-white text-7xl'>ERREUR</h2>
        </div>

        {errorVideoUrl?.fileUrl ? (
          <div className='w-1/2 h-full'>
            <video
              className='object-cover w-full h-full'
              autoPlay
              loop
              muted
              width={350}
              height={350}
              src={errorVideoUrl.fileUrl}
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='w-3/6 mt-11'>
        <div className='h-32'>
          <Notification stepCategory='error' text={errorMessage.received_message} />
        </div>
        <div className='h-32'>
          <Notification stepCategory='warn' text={errorMessage.expected_message} />
        </div>
      </div>
    </>
  );
};

const ErrorInArea = ({ isErrorOnArea }) => {
  if (!isErrorOnArea?.state) return null;

  const { title, content } = isErrorOnArea;

  return (
    <>
      <div className='flex flex-col w-3/6 gap-4'>
        <div className={`w-full border-4 border-dashed`}>
          <h2 className='py-24 text-center text-white text-7xl'>{title}</h2>
        </div>
        <div className='flex flex-col items-center justify-center w-full text-white'>
          <h3 className='text-3xl'></h3>
          <p className='text-xl text-center'>{content}</p>
        </div>
      </div>
    </>
  );
};
